import React from 'react';
import styled from 'styled-components';

const NUMBER_OF_ICONS = 2;

const SignalIcon = ({ level, maxLevel }) => {
	const Level10 = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="18"
			viewBox="0 0 23 18"
		>
			<g id="Group_25" data-name="Group 25" transform="translate(-96 1923)">
				<rect
					id="Rectangle_32"
					data-name="Rectangle 32"
					width="3"
					height="18"
					rx="1.5"
					transform="translate(96 -1923)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_33"
					data-name="Rectangle 33"
					width="3"
					height="15"
					rx="1.5"
					transform="translate(101 -1920)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_34"
					data-name="Rectangle 34"
					width="3"
					height="10"
					rx="1.5"
					transform="translate(106 -1915)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_35"
					data-name="Rectangle 35"
					width="3"
					height="6"
					rx="1.5"
					transform="translate(111 -1911)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_36"
					data-name="Rectangle 36"
					width="3"
					height="3"
					rx="1.5"
					transform="translate(116 -1908)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
	const Level20 = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="18"
			viewBox="0 0 23 18"
		>
			<g id="Group_24" data-name="Group 24" transform="translate(-96 1945)">
				<rect
					id="Rectangle_32"
					data-name="Rectangle 32"
					width="3"
					height="18"
					rx="1.5"
					transform="translate(96 -1945)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_33"
					data-name="Rectangle 33"
					width="3"
					height="15"
					rx="1.5"
					transform="translate(101 -1942)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_34"
					data-name="Rectangle 34"
					width="3"
					height="10"
					rx="1.5"
					transform="translate(106 -1937)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_35"
					data-name="Rectangle 35"
					width="3"
					height="6"
					rx="1.5"
					transform="translate(111 -1933)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_36"
					data-name="Rectangle 36"
					width="3"
					height="3"
					rx="1.5"
					transform="translate(116 -1930)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
	const Level30 = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="18"
			viewBox="0 0 23 18"
		>
			<g id="Group_23" data-name="Group 23" transform="translate(-96 1967)">
				<rect
					id="Rectangle_32"
					data-name="Rectangle 32"
					width="3"
					height="18"
					rx="1.5"
					transform="translate(96 -1967)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_33"
					data-name="Rectangle 33"
					width="3"
					height="15"
					rx="1.5"
					transform="translate(101 -1964)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_34"
					data-name="Rectangle 34"
					width="3"
					height="10"
					rx="1.5"
					transform="translate(106 -1959)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_35"
					data-name="Rectangle 35"
					width="3"
					height="6"
					rx="1.5"
					transform="translate(111 -1955)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_36"
					data-name="Rectangle 36"
					width="3"
					height="3"
					rx="1.5"
					transform="translate(116 -1952)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
	const Level40 = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="18"
			viewBox="0 0 23 18"
		>
			<g id="Group_22" data-name="Group 22" transform="translate(-96 1989)">
				<rect
					id="Rectangle_32"
					data-name="Rectangle 32"
					width="3"
					height="18"
					rx="1.5"
					transform="translate(96 -1989)"
					fill="#4b4b4b"
				/>
				<rect
					id="Rectangle_33"
					data-name="Rectangle 33"
					width="3"
					height="15"
					rx="1.5"
					transform="translate(101 -1986)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_34"
					data-name="Rectangle 34"
					width="3"
					height="10"
					rx="1.5"
					transform="translate(106 -1981)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_35"
					data-name="Rectangle 35"
					width="3"
					height="6"
					rx="1.5"
					transform="translate(111 -1977)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_36"
					data-name="Rectangle 36"
					width="3"
					height="3"
					rx="1.5"
					transform="translate(116 -1974)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
	const Level50 = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="18"
			viewBox="0 0 23 18"
		>
			<g id="Group_21" data-name="Group 21" transform="translate(-96 2011)">
				<rect
					id="Rectangle_32"
					data-name="Rectangle 32"
					width="3"
					height="18"
					rx="1.5"
					transform="translate(96 -2011)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_33"
					data-name="Rectangle 33"
					width="3"
					height="15"
					rx="1.5"
					transform="translate(101 -2008)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_34"
					data-name="Rectangle 34"
					width="3"
					height="10"
					rx="1.5"
					transform="translate(106 -2003)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_35"
					data-name="Rectangle 35"
					width="3"
					height="6"
					rx="1.5"
					transform="translate(111 -1999)"
					fill="#fff"
				/>
				<rect
					id="Rectangle_36"
					data-name="Rectangle 36"
					width="3"
					height="3"
					rx="1.5"
					transform="translate(116 -1996)"
					fill="#fff"
				/>
			</g>
		</svg>
	);

	const normalizedLevel = (() => ((level / maxLevel) * 100) / 2)();

	// console.log({ normalizedLevel });

	if (normalizedLevel <= 10) return <Level10 />;
	if (normalizedLevel > 10 && normalizedLevel <= 20) return <Level20 />;
	if (normalizedLevel > 20 && normalizedLevel <= 30) return <Level30 />;
	if (normalizedLevel > 30 && normalizedLevel <= 40) return <Level40 />;
	if (normalizedLevel > 40 && normalizedLevel <= 50) return <Level50 />;
	else return <Level10 />;
};

const SignalStrength = ({ levels, maxLevel }) => {
  const highestLevels = levels.sort((a,b) => a > b ? -1 : 1).slice(0,2)

	return (
		<SignalContainer>
			{[...highestLevels, ...Array(NUMBER_OF_ICONS - highestLevels.length)].map((level) => {
				return <SignalIcon level={level} maxLevel={maxLevel ?? 100} />;
			})}
		</SignalContainer>
	);
};

const SignalContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 60px;
`;

export default SignalStrength;
