import XYZeronLogoSrc from '../../assets/img/android-chrome-256x256.png';
import BatteryIcon from '../icons/BatteryIcon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AppTheme from '../theme';
import { useTranslation } from 'react-i18next';
import { MapSubTitle } from '../mainPanel/MainPanel';

export const CRITICAL_INDICATOR = 'WARNING';
export const WARNING_INDICATOR = 'ALERT';

const RenderNotification = ({
	connectedState,
	connectedText,
	disconnectedText,
}) => (
	<>
		{connectedState ? (
			<ConnContainer>
				<IndicatorContainer>
					<Connected />
				</IndicatorContainer>
				<ConnectedText>{connectedText}</ConnectedText>
			</ConnContainer>
		) : (
			<ConnContainer>
				<IndicatorContainer>
					<Disconected />
				</IndicatorContainer>
				<ConnectedText>{disconnectedText}</ConnectedText>
			</ConnContainer>
		)}
	</>
);

const SidePanel = ({ alertSegments, errorState, connectedState }) => {
	const { t } = useTranslation();

	return (
		<SidePanelContainer>
			<SidePanelHeader>
				<XYZeronLogo src={XYZeronLogoSrc} />
				<MapSubtitleContainer>
					<MapSubTitle>{t('panelHeader')}</MapSubTitle>
					<RenderNotification
						connectedText={t('connectedText')}
						disconnectedText={t('disconnectedText')}
						connectedState={connectedState}
					/>
				</MapSubtitleContainer>
			</SidePanelHeader>
			<SidePanelBody>
				{alertSegments.length === 0 ? (
					<NoContentContainer>
						<NoContentText $error={errorState}>
							{errorState ? t('fetchError') : t('noContent')}
						</NoContentText>
					</NoContentContainer>
				) : (
					alertSegments.map((item, index) => {
						const desc = item.description.split(',');
						const alert = item.alert.split('_')[0];

						return (
							item.alert !== 'NONE' && (
								<SidePanelBodyItem key={index} status={alert ?? '-'}>
									<NodeTitleWrapper>
										<NodeNumber status={alert ?? '-'}>
											{item.id ?? '-'}
										</NodeNumber>
										<NodeTitle status={alert ?? '-'}>
											{desc[0] ?? t('warningTitle')}
										</NodeTitle>
									</NodeTitleWrapper>
									<NodeSubTitle>{desc[1]}</NodeSubTitle>
									<NodeStatusWrapper>
										<NodeText status={alert ?? ''}>
											{t('angleStatus', {
												angleVal: item.degreeToNextSegment?.toFixed(2)
													? `${item.degreeToNextSegment?.toFixed(2)}°`
													: '-',
											})}
										</NodeText>
										<NodeStatusSeperator />
										<BatteryStatusWrapper>
											<BatteryIcon />
											<NodeText>
												{item.sensorDevice?.batteryPresent
													? `${item.sensorDevice?.batteryPresent}%`
													: '-'}
											</NodeText>
										</BatteryStatusWrapper>
										<NodeStatusSeperator />
										<NodeText>
											{item.sensorDevice?.status === 'IS_ALIVE'
												? t('sensorActive')
												: t('sensorInActive')}
										</NodeText>
									</NodeStatusWrapper>
								</SidePanelBodyItem>
							)
						);
					})
				)}
			</SidePanelBody>
			<SidePanelFooter>
				<Link to="/details">
					<SidePanelFooterCta>{t('footerCTA')}</SidePanelFooterCta>
				</Link>
			</SidePanelFooter>
		</SidePanelContainer>
	);
};

export default SidePanel;

const NoContentContainer = styled.div`
	width: 100%;
	padding: 20px 0px;
`;

const NoContentText = styled.p`
	color: ${(props) =>
		props.$error ? AppTheme.colors.tomatoRed : AppTheme.colors.monoLight};
	text-align: center;
	padding: 0px;
	margin: 0px;
`;

const SidePanelContainer = styled.div`
	width: 20%;
	height: 100%;
	background: ${AppTheme.colors.darkBg};
	border-right: 1px solid ${AppTheme.colors.lightMuted};
	display: flex;
	flex-direction: column;
`;

const SidePanelHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	height: 150px;
	border-bottom: 1px solid ${AppTheme.colors.lightMuted};
	color: ${AppTheme.colors.monoLight};
`;

const SidePanelBody = styled.div`
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow-y: scroll;
	/* width */
	&::-webkit-scrollbar {
		width: 10px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: #1f1f1f;
	}
	s
 
/* Handle */
&::-webkit-scrollbar-thumb {
		background: #ccc;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #888;
	}
`;

const SidePanelBodyItem = styled.div`
	padding: 10px 10px;
	display: flex;
	flex-direction: column;
	${({ status, theme }) => {
		if (status === WARNING_INDICATOR) {
			return `border-${theme.alignment}: 10px solid ${AppTheme.colors.orange};`;
		}
		if (status === CRITICAL_INDICATOR) {
			return `border-${theme.alignment}: 10px solid ${AppTheme.colors.tomatoRed};`;
		} else {
			return `border-${theme.alignment}: 10px solid ${AppTheme.colors.monoLight};`;
		}
	}}
`;

const NodeNumber = styled.div`
	${({ status, theme }) => {
		if (status === WARNING_INDICATOR) {
			return `background: ${AppTheme.colors.orange};
			`;
		}
		if (status === CRITICAL_INDICATOR) {
			return `background: ${AppTheme.colors.tomatoRed};`;
		} else {
			return `background: ${AppTheme.colors.monoLight};`;
		}
	}}
	${({ theme }) => {
		return `margin-${theme.lang.dir ? 'left' : 'right'}: 10px;`;
	}}
	border: 2px solid ${AppTheme.colors.monoLight};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${AppTheme.colors.monoLight};
	width: 35px;
	height: 35px;
	border-radius: 35px;
	font-size: 11px;
`;

const NodeTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const NodeTitle = styled.h3`
	${({ status }) => {
		if (status === WARNING_INDICATOR) {
			return `color: ${AppTheme.colors.orange};`;
		}
		if (status === CRITICAL_INDICATOR) {
			return `color: ${AppTheme.colors.tomatoRed};`;
		} else {
			return `color: ${AppTheme.colors.monoLight};`;
		}
	}}
	font-weight: bold;
`;

const NodeSubTitle = styled.p`
	color: ${AppTheme.colors.monoLight};
	font-weight: bold;
`;

const NodeStatusWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 50px;
	align-items: center;
	color: ${AppTheme.colors.monoLight};
`;

const NodeStatusSeperator = styled.div`
	width: 0;
	height: 100%;
	margin: 0px 10px;
	border-right: 1px solid ${AppTheme.colors.lightMuted};
`;

const NodeText = styled.p`
	${({ status }) => {
		if (status === WARNING_INDICATOR) {
			return `color: ${AppTheme.colors.orange};`;
		}
		if (status === CRITICAL_INDICATOR) {
			return `color: ${AppTheme.colors.tomatoRed};`;
		} else {
			return `color: ${AppTheme.colors.monoLight};`;
		}
	}}
`;

const BatteryStatusWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const SidePanelFooter = styled.div`
	height: 80px;
	border-top: 1px solid ${AppTheme.colors.lightMuted};
	border-bottom: 1px solid ${AppTheme.colors.lightMuted};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	background: ${AppTheme.colors.darkBg};
`;

const SidePanelFooterCta = styled.button`
	border: 0px;
	width: 100%;
	height: 100%;
	outline: none;
	color: ${AppTheme.colors.monoLight};
	background: ${AppTheme.colors.darkBg};
`;

const XYZeronLogo = styled.img`
	width: 70px;
	height: 70px;
`;

const MapSubtitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Connected = styled.div`
	height: 15px;
	width: 15px;
	background-color: green;
	border-radius: 50%;
	margin-right: 0.5rem;
`;

const Disconected = styled.div`
	height: 15px;
	width: 15px;
	background-color: red;
	border-radius: 50%;
	margin-right: 0.5rem;
`;

const IndicatorContainer = styled.div``;

const ConnContainer = styled.div`
	display: flex;
	align-items: center;
`;

const ConnectedText = styled.div``;
