import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SignalStrength from '../components/icons/SignalStrength';
import RoundAddIcon from '../components/icons/RoundAddIcon';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import theme from '../components/theme';
import { useTranslation } from 'react-i18next';
import { FetchRigData } from '../utils/FetchRigData';
import { RIGContext } from '../context/RigDataContext';
import { handleProcessData, sortData } from '../utils/ProcessRigData';
import { UpdateNote } from '../utils/UpdateNote';
import qs from 'query-string'
import { AUTH_TOKEN, MAP_STYLE_URL } from '../config';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { InitSimulation } from '../utils/InitSimulation';
import { getUpdatedData } from '../utils/GetUpdatedData';
import Moment from 'moment';

let delayUpdateComment;
let thirtyMinMilli = 1800000

const RenderNotesInput = ({index, node, onTextChange }) => {
	const { t } = useTranslation();

	return (
		<RenderNotesContainer>
			{!node.comments && <RoundAddIcon />}
			<NotesInput
				value={node.comments ?? ''}
				placeholder={t('notesPlaceHolder')}
				multiline={true}
				textAlignVertical={true}
				onChange={(text)=>{
					onTextChange({index, segmentId: node.id, comment: text.target.value })}}
			/>
		</RenderNotesContainer>
	);
};

export default function TableView() {

	const { t } = useTranslation();
	const {
		rigData,
		isLoading,
		setLoading,
		setRigData,
		setErrorState,
		errorState,
		selectedPoolState,
		setSelectedPoolState,
	} = useContext(RIGContext);

	const stompClientRef = useRef(null);
	const rigDataRef = useRef([]);

	const [connectedState, setConnectedState] = useState(false);

	useEffect(() => {
		const poolId = qs.parseUrl(window.location.href).query.poolId
		console.warn({poolId, selectedPoolState})
		poolId && setSelectedPoolState(poolId)

		if(poolId && selectedPoolState !== poolId) return

	 	initSim(selectedPoolState)

		return () => disconnectWS()
	}, [selectedPoolState]);

	// useEffect(() => {
	// 	console.log('effect logger', { connectedState, stompClientRef, selectedPoolState, rigData, rigDataRef });
	// }, [connectedState, stompClientRef, selectedPoolState, rigData]);
	
	const initSim = async (selectedPool) => {
		if (stompClientRef.current !== null) {
			disconnectWS()
		}
		setLoading(true);

		const fetchedRigData = await FetchRigData(selectedPool)

		// const initSim = await InitSimulation(selectedPool);

		if(!fetchedRigData || !initSim){
			setErrorState(true)
			setLoading(false);

			return;
		}

		const processedData = handleProcessData(fetchedRigData);
		console.warn({fetchedSegments:processedData})

		rigDataRef.current = processedData
		setRigData([...processedData]);
		setLoading(false)

		connectToWS(selectedPool)
	}

	function setConnected(connected) {
		setConnectedState(connected);
	}

	function connectToWS(poolId) {
		console.log('connecting to WS', poolId)
		var socket = new SockJS('https://app.xyzeron.com/api/kavTzaf-websocket');
		var topic = `/pool/${poolId}`;
		stompClientRef.current = Stomp.over(socket);
		stompClientRef.current.connect(
			{ 'X-Authorization': AUTH_TOKEN },
			function (frame) {
				console.log('Connected: ' + frame);

				setConnected(true);
				setLoading(false);

				stompClientRef.current.subscribe(topic, onMessageReceived);
			}
		);
	}

	const onMessageReceived = (data) => {
		// console.log('Receieved message: ', data);
		
		const parsedData = JSON.parse(data.body);
		const sortedData = sortData(parsedData);
		// console.log({sortedData})
		const updatedData = getUpdatedData(rigDataRef.current, sortedData);

		rigDataRef.current = updatedData
		setRigData([...updatedData])
	}

	function disconnectWS() {
		if (stompClientRef.current !== null) {
			stompClientRef.current.disconnect();
		}
		setConnected(false);
		// console.log('Disconnected');
	}

	const updateComment = ({index, segmentId, comment}) => {
		// console.log({index, segmentId, comment})

		const segments = rigData
		segments[index] = {
			...segments[index],
			comments: comment
		}
		setRigData([...segments])

		clearTimeout(delayUpdateComment);
		delayUpdateComment= setTimeout(
			() => UpdateNote({segmentId, comment}),
			1500
		);
	}

	if (isLoading)
		return (
			<TableViewContainer>
				<Spinner color={theme.colors.monoLight} />
			</TableViewContainer>
		);

	return (
		<TableViewContainer>
			<NavigationContainer>
				<Link to="/">
					<BackButton>{t('showMap')}</BackButton>
				</Link>
			</NavigationContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>{t('sensorField')}</TableHeader>
						<TableHeader>{t('serialField')}</TableHeader>
						<TableHeader>{t('numberField')}</TableHeader>
						<TableHeader>{t('signalField')}</TableHeader>
						<TableHeader>{t('rigField')}</TableHeader>
						<TableHeader>{t('batteryField')}</TableHeader>
						<TableHeader>{t('lineField')}</TableHeader>
						<TableHeader>{t('lastUpdate')}</TableHeader>
						<TableHeader>{t('notesField')}</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{rigData.map((node, index) => {
						const buffer = node?.sensorDevice?.keepAliveInterval * 3 * 1000
						const isOutDated = Number(Moment(node?.sensorDevice?.lastUpDate).format('x')) + buffer < Date.now()
				
						return (
							<TableRow key={index}>
								<TableData>{node.index ?? '-'}</TableData>
								<TableData>{node.sensorDevice?.deviceEUI ?? '-'}</TableData>
								<TableData>{node.name ?? '-'}</TableData>
								<TableData $align="center">
									<CenteredData>
										<SignalStrength
											levels={node.sensorDevice?.rssiPresentList ?? []}
											maxLevel={100}
										/>
									</CenteredData>
								</TableData>
								<TableData>{node?.bearing ? `${node.bearing?.toFixed(1)}°` : '-'}</TableData>
								<TableData>{node.sensorDevice?.batteryPresent ? `${node.sensorDevice?.batteryPresent}%` : '-'}</TableData>
								<TableData>{node.length ?? '-'}</TableData>
								<TableData isOutDated={isOutDated}>{node?.sensorDevice?.lastUpDate ? Moment(node?.sensorDevice?.lastUpDate).format('HH:mm, DD/MM/yyyy') : '-'}</TableData>
								<TableData>
									<RenderNotesInput
										index={index}
										node={node}
										onTextChange={updateComment}
									/>
								</TableData>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableViewContainer>
	);
}

export const TableViewContainer = styled.div`
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: #1d1d1d;
	overflow-y: scroll;
	padding: 50px 0px;
	&::-webkit-scrollbar {
  width: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #1f1f1f; 
}s
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: #ccc; 
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #888; 
}
`;

export const Table = styled.table`
	border: 1px solid ${theme.colors.lightMuted};
	width: 90%;
	color: white;
	border: 1px solid ${theme.colors.lightMuted};
	border-collapse: collapse;
`;

export const TableBody = styled.tbody`
`;

export const TableHead = styled.thead`
`;

export const TableRow = styled.tr`
	border: 1px solid ${theme.colors.lightMuted};
`;

export const TableData = styled.td`
	border: 1px solid ${theme.colors.lightMuted};
	padding: 10px 20px;
	${(props)=> props.isOutDated ? `color: ${theme.colors.orange}` : `color: ${theme.colors.monoLight}`}
`;

export const TableHeader = styled.th`
	padding: 10px 20px;
	border: 1px solid ${theme.colors.lightMuted};
`;

export const CenteredData = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NotesInput = styled.input`
	background: ${theme.colors.darkBg};
	outline: none;
	border: 0px solid white;
	padding: 10px 10px;
	color: ${theme.colors.monoLight};
	flex: 1;

	::placeholder,
	::-webkit-input-placeholder {
		color: ${theme.colors.mutedText};
	}
	:-ms-input-placeholder {
		color: ${theme.colors.mutedText};
	}
`;

export const RenderNotesContainer = styled.div`
	display: flex;
	align-items: center;
`;

const NavigationContainer = styled.div`
	width: 90%;
	margin: 20px;
`

const BackButton = styled.button`
	border: 0px;
	outline: none;
	color: ${theme.colors.monoLight};
	background: ${theme.colors.darkBg};
	font-weight: bold
`;