import findDegreesToNextSegment from './FindDegreesToNextSegment';


export const sortData = (data) => data.sort((a, b) => {
	if (a.id < b.id) {
		return -1;
	}
	if (a.id > b.id) {
		return 1;
	}
	return 0;
});

export const handleProcessData = (data) => {
	const sortedData=sortData(data)
	console.log({ sortedData });

	return sortedData;
};
