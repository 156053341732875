const findDegreesToNextSegment = (source, destination) => {
	console.log({ source, destination });

	const cos = (val) => Math.cos((val * Math.PI) / 180);
	const sin = (val) => Math.sin((val * Math.PI) / 180);

	const changeInLongitude = destination.y - source.y;

	const X = cos(destination.x) * sin(changeInLongitude);
	const Y =
		cos(source.x) * sin(destination.x) -
		sin(source.x) * cos(destination.x) * cos(changeInLongitude);

	return (Math.atan2(X, Y) * 180) / Math.PI;
};

export default findDegreesToNextSegment;
