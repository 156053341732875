import React from "react";

const BatteryIcon = ({ color }) => (
  <div
    style={{
      marginRight: 10,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="19.864"
      viewBox="0 0 10 19.864"
    >
      <path
        id="Icon_awesome-battery-full"
        data-name="Icon awesome-battery-full"
        d="M16.884,8.75v2h.993v2h-.993v2H1.986v-6h14.9m.5-2H1.49A1.5,1.5,0,0,0,0,8.25v7a1.5,1.5,0,0,0,1.49,1.5H17.381a1.5,1.5,0,0,0,1.49-1.5v-.5h.248a.747.747,0,0,0,.745-.75V9.5a.747.747,0,0,0-.745-.75h-.248v-.5A1.5,1.5,0,0,0,17.381,6.75Zm-1.49,3H2.98v4H15.891Z"
        transform="translate(-6.75 19.864) rotate(-90)"
        fill="#fff"
      />
    </svg>
  </div>
);

export default BatteryIcon;
