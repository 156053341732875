import { REMOTE_URL } from '../config';
import { fetchWithAuth } from './FetchUtil';
import qs from 'query-string'

export const SetStartPoint = async ({startPointId,poolId}) => {

	try {

        const params = qs.stringify({poolId, startPointId}, {sort: false})

        console.warn({params, startPointId, poolId, url: `${REMOTE_URL}/setStartPoint?${params}`})

		const response = await fetchWithAuth(
			`${REMOTE_URL}/setStartPoint?${params}`, {
                method: 'PUT'
            }
		);

		const data = await response.json();
		console.log({ data });

		if(data.status === 401){
			throw data
		}

		return data;
	} catch (e) {
		console.log('SetStartPoint caught', e);
		return undefined;
	}

};
