export const getUpdatedData = (oldData, newData) => {

	// console.log({ oldData, newData });

	const firstSegment = newData[0];
	const targetSegmentIndex = oldData.findIndex((i) => {
		return i.id === firstSegment.id;
	});
	// console.log({
	// 	targetSegmentIndex,
	// 	firstSegment,
	// 	targetSegment: oldData[targetSegmentIndex],
	// });

	const unchangedSegments =
		targetSegmentIndex === -1
			? []
			: getAllBefore(targetSegmentIndex, oldData);

	const updatedSegments = getUpdatedSegments({targetSegmentIndex, oldData, newData})

	const updatedData = [...unchangedSegments, ...updatedSegments];
	// console.log({ unchangedSegments, updatedData });

	return updatedData;
};

const getAllBefore = (i, array) => {

	return i > -1 ? array.slice(0, i) : [];

};

const getAllAfter = (i, array) => {

	return i > -1 ? array.slice(i, array.length) : [];

};

const getUpdatedSegments = ({targetSegmentIndex, oldData, newData}) => {
	const changedSegments = targetSegmentIndex === -1 ? oldData : getAllAfter(targetSegmentIndex, oldData)
	const updatedSegments = changedSegments.map((segment, index)=>{
		// console.log({segment, updatedSegments: newData[index]})
		return {
			...segment,
			...newData[index]
		}
	})
	// console.log('updatedSegments', {changedSegments, updatedSegments, newData})
	return updatedSegments
}