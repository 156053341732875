import { useState, createContext } from "react";

export const RIGContext = createContext();

const RIGContextProvider = ({ children }) => {
  const [rigData, setRigData] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedPoolState, setSelectedPoolState] = useState(1);

  return (
		<RIGContext.Provider
			value={{
				rigData,
				isLoading,
				setLoading,
				setRigData,
				errorState,
				setErrorState,
				selectedPoolState,
				setSelectedPoolState,
			}}
		>
			{children}
		</RIGContext.Provider>
	);
};

export default RIGContextProvider;
