const theme = {
  colors: {
    lightMuted: "#4e4e4e",
    monoLight: "#fff",
    darkBg: "#1d1d1d",
    tomatoRed: "#DF5158",
    mutedText: "#a2a2a2",
    orange: "#EE9B54",
  },
};

export default theme;
