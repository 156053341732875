import GoogleMapView from './screens/MapView';
import TableView from './screens/TableView';
import MapView from './screens/MapView';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import RIGContextProvider from './context/RigDataContext';
import React, { useEffect, createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { useState } from 'react/cjs/react.development';
import styled, { ThemeProvider as StyleComponentThemeProvider } from 'styled-components';
import {BASE_NAME, REMOTE_URL} from "./config";

export const AppContext = createContext();

export const languages = [
	{
		code: 'he',
		name: 'עִברִית',
		country_code: 'il',
		dir: 'rtl',
	},
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
];

function LanguageSwitcher() {
	const { t } = useTranslation();
	const [menuState, setMenuState] = useState(false);


	const handleSelectitem = (c) => {
		setMenuState(false);
		i18next.changeLanguage(c);
	};

	const handleOpenMenu = () => {
		setMenuState(!menuState);
	};

	return (
		<Dropdown>
			{menuState && (
				<DropdownListContainer>
					{languages.map(({ code, name, country_code }) => (
						<DropdownListItem key={country_code}>
							<a
								className="languageSelector"
								href="/"
								onClick={() => handleSelectitem(code)}
							>
								{name}
							</a>
						</DropdownListItem>
					))}
				</DropdownListContainer>
			)}
			<DropdownBtn onClick={handleOpenMenu}>{menuState ? t('close') : t('language')}</DropdownBtn>
		</Dropdown>
	);
}

function App() {
	
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const [currentLanguage, setCurrentLanguage] = useState(languages.find((l) => l.code === currentLanguageCode));
	const { t } = useTranslation();

	useEffect(() => {
		// console.log('Setting page stuff', {currentLanguage});
		document.body.dir = currentLanguage.dir || 'ltr';
		document.title = t('appTitle');
	}, [currentLanguage, t]);
	return (
		<StyleComponentThemeProvider
			theme={{
				lang: currentLanguage,
				alignment: currentLanguage.dir === 'rtl' ? 'right' : 'left',
			}}
		>
			<RIGContextProvider>
				<LanguageSwitcher />
				<Router >
					<Switch>
						<Route exact path="/" component={MapView} />
						<Route exact path="/details" component={TableView} />
					</Switch>
				</Router>
			</RIGContextProvider>
		</StyleComponentThemeProvider>
	);
}

export default App;

const Dropdown = styled.div`
		position: fixed;
		bottom: 0;
		z-index: 1000;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 20px;
		box-sizing: border-box;
		${({theme}) => {
		if (theme.lang.dir === 'rtl') {
			return `left: 0`;
		} else {
			return `right: 0`;
		}
	}}
	`;

	const DropdownBtn = styled.button`
		width: 100px;
	`;

	const DropdownListContainer = styled.ul`
		background: white;
		width: 150px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		box-sizing: border-box;
	`;

	const DropdownListItem = styled.li`
		list-style-type: none;
		width: 100%;
		display: flex;
		justify-content: flex-end;

		a {
			padding: 10px;
			color: black;
			text-decoration: none;
			height: 100%;
			width: 100%;
		}

		:hover {
			background-color: rgba(0, 0, 0, 0.15);
		}
	`;
