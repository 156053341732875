import { REMOTE_URL } from '../config';
import { fetchWithAuth } from './FetchUtil';

export const InitSimulation = async (poolId) => {
	try {
		const response = await fetchWithAuth(
			`${REMOTE_URL}/initSimulation?poolId=${poolId}`, {
				method: "POST"
			}
		);

		const data = await response.json();
		console.log({ data });

		if (data.status === 401) {
			throw data;
		}

		return data;
	} catch (e) {
		console.log('init sim caught', e);
		return undefined;
	}
};
