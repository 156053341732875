import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NormalNode from '../../assets/img/Ellipse 1.png';
import WarninglNode from '../../assets/img/Group 1.png';
import HeadNode from '../../assets/img/rigHeadNode.png';
import StartPointNode from '../../assets/img/startPoint.png';
import ErrorNode from '../../assets/img/Group 2.png';
import {
	MapContainer,
	TileLayer,
	Marker,
	Polyline,
	Polygon,
} from 'react-leaflet';
import L from 'leaflet';
import theme from '../theme';
import { mapAttribution, mapStyleUrl } from '../../screens/MapView';
import { CRITICAL_INDICATOR, WARNING_INDICATOR } from '../sidePanel/SidePanel';
import { useEffect, useState } from 'react';
import { SetStartPoint } from '../../utils/SetStartPoint';
import { CloseIcon } from '../../assets/svg/CloseIcon';

const headNodeMarker = L.icon({
	//custom icons for markers
	iconUrl: HeadNode,
	iconSize: [22, 70],
});

const normalNodeMarker = L.icon({
	//custom icons for markers
	iconUrl: NormalNode,
	iconSize: [12, 12],
});

const warningNodeMarker = L.icon({
	iconUrl: WarninglNode,
	iconSize: [42, 42],
});

const errorNodeMarker = L.icon({
	iconUrl: ErrorNode,
	iconSize: [42, 42],
});

const startPointNodeMarker = L.icon({
	iconUrl: StartPointNode,
	iconSize: [12, 12],
});

const RenderStartPoints = ({
	startPoints,
	selectedPoolId,
	setStartPointState,
}) => {
	return startPoints.map((node, index) => (
		<Marker
			key={index}
			icon={startPointNodeMarker}
			position={[node.longitude, node.latitude]}
			eventHandlers={{
				click: async () => {
					// console.log(`Start point ${node.id} clicked`, { node });
					await SetStartPoint({
						startPointId: node.id,
						poolId: selectedPoolId,
					});
					setStartPointState(node);
				},
			}}
		/>
	));
};

const MainPanel = ({
	defaultProps,
	rigData,
	setMap,
	selectedPoolId,
	poolData,
	startPoints = [],
}) => {
	// console.log({ poolData });
	const { t } = useTranslation();
	const [selectedSegmentState, setSelectedSegment] = useState({});
	const [startPointState, setStartPointState] = useState({});

	const addStartPointToState = (point) => {
		setStartPointState(point);
	};

	// useEffect(() => {
		// console.log(selectedSegmentState);
	// }, []);
	const renderPolylines = [
		...rigData,
		...(Object.keys(startPointState).length !== 0
			? [
					{
						...startPointState,
						endLatitude: startPointState.latitude,
						endLongitude: startPointState.longitude,
					},
			  ]
			: []),
	].map((node) => {
		//format rigdata to leaflet accepted format for rendering polyline
		return [node.endLongitude, node.endLatitude];
	});

	//   useEffect(() => {
	//     console.log("setting init rig data", { rigData });
	//     if (rigData.length > 0 && Object.keys(selectedSegmentState).length === 0) {
	//       setSelectedSegment(rigData[0]);
	//     }
	//   }, []);
	// console.log(selectedSegmentState);
	return (
		<MapViewWrapper>
			<MapTitleWrapper>
				<MapTitle>{t('mapTitle')}</MapTitle>
				<MapSubTitle>{`${t('mapSubTitle')}- ${selectedPoolId}`}</MapSubTitle>
				<NodeDetails
					segmentSelected={
						Object.keys(selectedSegmentState).length !== 0 ? true : false
					}
				>
					{Object.keys(selectedSegmentState).length !== 0 ? (
						<NodeDetailsHeader
							onClick={() => {
								setSelectedSegment({});
							}}
						>
							<CloseIcon />
						</NodeDetailsHeader>
					) : null}
					{Object.keys(selectedSegmentState).length !== 0 ? (
						Object.keys(selectedSegmentState ?? {}).map((key, index) => {
							return typeof selectedSegmentState[key] !== 'object' ? (
								<MapSubTitle >{`${key}: ${selectedSegmentState[key]}`}</MapSubTitle>
							) : (
								Object.keys(selectedSegmentState[key] ?? {}).map(
									(subKey, subIndex) => {
										return (
											<MapSubTitle>{`${subKey}: ${selectedSegmentState[key][subKey]}`}</MapSubTitle>
										);
									}
								)
							);
						})
					) : (
						<MapSubTitle>{t('mainPanelSegmentText')}</MapSubTitle>
					)}
				</NodeDetails>
			</MapTitleWrapper>
			<MapContainerWrapper>
				<MapContainer
					center={
						rigData?.length > 0
							? [rigData[0].endLongitude, rigData[0].endLatitude]
							: [defaultProps.center.lat, defaultProps.center.lng]
					}
					zoom={defaultProps.zoom}
					scrollWheelZoom={false}
					whenCreated={setMap}
				>
					<TileLayer attribution={mapAttribution} url={mapStyleUrl} />
					<Polyline
						pathOptions={{ color: 'white' }}
						positions={renderPolylines}
					/>
					{rigData.map((node, index) => {
						const alert = node.alert.split('_')[0];
						const nodeMarker = (() => {
							//decide which marker to render based on status of node
							if (alert === WARNING_INDICATOR) {
								return warningNodeMarker;
							} else if (alert === CRITICAL_INDICATOR) {
								return errorNodeMarker;
							} else {
								return normalNodeMarker;
							}
						})();

						if (index === 0) {
							//set different marker for the rig itself
							return (
								<Marker
									key={index}
									position={[node.endLongitude, node.endLatitude]}
									icon={headNodeMarker}
									eventHandlers={{
										click: () => {
											// console.log(`Head node ${node.index} clicked`, { node });
											setSelectedSegment(node);
										},
									}}
								/>
							);
						} else {
							return (
								<Marker
									key={index}
									icon={nodeMarker}
									position={[node.endLongitude, node.endLatitude]}
									eventHandlers={{
										click: () => {
											// console.log(`segment ${node.index} clicked`, { node });
											setSelectedSegment(node);
										},
									}}
								/>
							);
						}
					})}
					<RenderStartPoints
						startPoints={startPoints}
						selectedPoolId={selectedPoolId}
						setStartPointState={addStartPointToState}
					/>
					{poolData.map((item, index) => {
						// return <RenderPoolData data={item} />

						if (item.type === 'POOL_BORDER') {
							return (
								<Polyline
									pathOptions={{ color: 'grey' }}
									positions={item.points.map((node) => {
										//format rigdata to leaflet accepted format for rendering polyline
										return [node.longitude, node.latitude];
									})}
								/>
							);
						} else {
							return (
								<Polygon
									pathOptions={{
										color:
											item.type === 'ALERT'
												? theme.colors.orange
												: theme.colors.lightMuted,
									}}
									positions={item.points.map((node) => {
										//format rigdata to leaflet accepted format for rendering polyline
										return [node.longitude, node.latitude];
									})}
								/>
							);
						}
					})}
				</MapContainer>
			</MapContainerWrapper>
		</MapViewWrapper>
	);
};

export default MainPanel;

const MapContainerWrapper = styled.div`
	/* border: 2px solid red; */
	height: 100%;
	width: 100%;
	margin: 0;
	.leaflet-container {
		width: 100%;
		height: 100vh;
	}
	.leaflet-control-container {
		position: absolute;
		${({ theme }) => {
			return `${theme.lang.dir ? 'left' : 'right'}: ${
				theme.lang.dir ? '5' : '56'
			}px;`;
		}}
	}
`;

const NodeDetails = styled.div`
	max-width: 500px;
	width: ${(props) => (props.segmentSelected ? '30vw' : 'auto')};
	height: ${(props) => (props.segmentSelected ? '75vh' : 'auto')};
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	border: 1px solid whitesmoke;
	/* border-radius: 10px; */
	margin-top: 10px;
	max-height: 700px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const NodeDetailsHeader = styled.div`
	width: 100%;
	height: 30px;
	margin-bottom: 10px;
	padding: 0px 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

const MapViewWrapper = styled.div`
	position: relative;
	width: 80%;
	height: 100%;
	color: ${theme.colors.monoLight};
`;

const MapTitleWrapper = styled.div`
	position: absolute;
	top: 20px;
	z-index: 2000;
	${({ theme }) => {
		return `${theme.lang.dir ? 'right' : 'left'}: 20px;`;
	}}
`;

export const MapSubTitle = styled.div`
	font-family: Arial, Helvetica, sans-serif;
	/* overflow: hidden;
white-space: normal */
	word-break: break-all;
	white-space: normal;
`;

const MapTitle = styled.h1`
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
`;
