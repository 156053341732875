import { REMOTE_URL } from '../config';
import { fetchWithAuth } from './FetchUtil';

export const FetchRigData = async (poolId) => {

	try {
		const response = await fetchWithAuth(
			`${REMOTE_URL}/getSegments?poolId=${poolId}`
		);

		const data = await response.json();
		console.log({ data });

		if(data.status === 401){
			throw data
		}

		return data;
	} catch (e) {
		console.log('FetchRigData caught', e);
		return undefined;
	}

};
