import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import theme from '../components/theme';

import { RIGContext } from '../context/RigDataContext';
import { FetchRigData } from '../utils/FetchRigData';
import { handleProcessData, sortData } from '../utils/ProcessRigData';
import MainPanel from '../components/mainPanel/MainPanel';
import SidePanel from '../components/sidePanel/SidePanel';
import { AUTH_TOKEN, MAP_STYLE_URL } from '../config';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { InitSimulation } from '../utils/InitSimulation';
import { getUpdatedData } from '../utils/GetUpdatedData';
import qs from 'query-string';
import { FetchPoolData } from '../utils/FetchPoolData';

//map style generated from mapbox studio for map design
export const mapStyleUrl = MAP_STYLE_URL;

//map credit attribution
export const mapAttribution =
	"© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>";

export default function MapView() {
	const defaultProps = {
		//default props: initialize map to the dead sea area
		center: {
			lat: 31.075675,
			lng: 35.411125,
		},
		zoom: 15.95,
	};

	const stompClientRef = useRef(null);
	const rigDataRef = useRef([]);

	const [map, setMap] = useState();
	const [connectedState, setConnectedState] = useState(false);
	const [alertSegmentsState, setAlertSegments] = useState([]);

	const [poolDataState, setPoolDataState] = useState({});

	const {
		rigData,
		isLoading,
		setLoading,
		setRigData,
		setErrorState,
		errorState,
		selectedPoolState,
		setSelectedPoolState,
	} = useContext(RIGContext);

	useEffect(() => {
		const poolId = qs.parseUrl(window.location.href).query.poolId;
		console.warn({ poolId, selectedPoolState });
		poolId && setSelectedPoolState(poolId);

		if (poolId && selectedPoolState !== poolId) return;

		initSim(selectedPoolState);

		return () => disconnectWS();
	}, [selectedPoolState]);

	// useEffect(() => {
	// 	console.log('effect logger', {
	// 		connectedState,
	// 		stompClientRef,
	// 		selectedPoolState,
	// 		rigData,
	// 		rigDataRef,
	// 	});
	// }, [connectedState, stompClientRef, selectedPoolState, rigData]);

	useEffect(() => {
		const alertSegments = [];

		rigData.forEach(
			(item) => item.alert !== 'NONE' && alertSegments.push(item)
		);

		console.warn({ alertSegments });
		setAlertSegments(alertSegments);
	}, [rigData]);

	// const fetchPoolData = async () => {//fetch static pool data
	// 	setLoading(true);

	// 	const fetchedRigData = await FetchRigData(selectedPoolState);

	// 	if(!fetchedRigData){
	// 		setErrorState(true)
	// 		setLoading(false);

	// 		return;
	// 	}

	// 	const processedData = handleProcessData(fetchedRigData);

	// 	rigDataRef.current = processedData
	// 	setRigData(processedData);
	// 	setLoading(false);
	// }

	const initSim = async (selectedPool) => {
		if (stompClientRef.current !== null) {
			disconnectWS();
		}
		setLoading(true);

		const fetchedPoolData = await FetchPoolData(selectedPool);
		const fetchedRigData = await FetchRigData(selectedPool);

		// const initSim =selectedPool === 1 ? await InitSimulation(selectedPool) : null

		if (!fetchedRigData) {
			setErrorState(true);
			setLoading(false);

			return;
		}

		const processedData = handleProcessData(fetchedRigData);
		console.warn({ fetchedSegments: processedData, fetchedPoolData });

		rigDataRef.current = processedData;
		setPoolDataState(fetchedPoolData);
		setRigData([...processedData]);
		setLoading(false);

		connectToWS(selectedPool);
	};

	function setConnected(connected) {
		setConnectedState(connected);
	}

	function connectToWS(poolId) {
		console.log('connecting to WS', poolId);

		var socket = new SockJS('https://app.xyzeron.com/api/kavTzaf-websocket');
		var topic = `/pool/${poolId}`;
		stompClientRef.current = Stomp.over(socket);
		stompClientRef.current.connect(
			{ 'X-Authorization': AUTH_TOKEN },
			function (frame) {
				console.log('Connected: ' + frame);

				setConnected(true);
				setLoading(false);

				stompClientRef.current.subscribe(topic, onMessageReceived);
			}
		);
	}

	const onMessageReceived = (data) => {
		console.log('Receieved message: ', data);

		const parsedData = JSON.parse(data.body);
		const sortedData = sortData(parsedData);
		console.log({ sortedData });
		const updatedData = getUpdatedData(rigDataRef.current, sortedData);

		rigDataRef.current = updatedData;
		setRigData([...updatedData]);
	};

	function disconnectWS() {
		if (stompClientRef.current !== null) {
			stompClientRef.current.disconnect();
		}
		setConnected(false);
		// console.log('Disconnected');
	}

	if (isLoading)
		return (
			<CenteredMapViewContainer>
				<Spinner color={theme.colors.monoLight} />
			</CenteredMapViewContainer>
		);

	return (
		<MapViewContainer>
			<SidePanel
				connectedState={connectedState}
				rigData={rigData}
				alertSegments={alertSegmentsState}
				errorState={errorState}
			/>
			<MainPanel
				defaultProps={defaultProps}
				rigData={rigData ?? []}
				startPoints={poolDataState?.startPoints ?? []}
				poolData={
					poolDataState.zones && poolDataState.zones.length > 0
						? poolDataState.zones
						: []
				}
				map={map}
				setMap={setMap}
				selectedPoolId={selectedPoolState}
			/>
		</MapViewContainer>
	);
}

const MapViewContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	background: ${theme.colors.darkBg};
	font-family: Arial, Helvetica, sans-serif;
	p {
		font-size: 14px;
	}
`;

const CenteredMapViewContainer = styled(MapViewContainer)`
	justify-content: center;
	align-items: center;
`;
