import { AUTH_TOKEN } from "../config";

export const fetchWithAuth = (url, options = {}) => {
	return new Promise(async (res, rej) => {
        try {
            const response = await fetch(url, {
                ...(Object.keys(options).length > 0 && {...options}),
                headers: {
                    Authorization: AUTH_TOKEN,
                    ...(options?.headers && {...options.headers})
                },
            });  
            res(response)
        } catch (error) {
            console.error('fetch with auth caught', {error, url, options})
            rej(error)
        }
	});
};
