import React from "react";

const RoundAddIcon = () => (
  <div
    style={{
      marginRight: 10,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.077"
      height="16.077"
      viewBox="0 0 16.077 16.077"
    >
      <g
        id="Icon_ionic-ios-add-circle-outline"
        data-name="Icon ionic-ios-add-circle-outline"
        transform="translate(-3.375 -3.375)"
      >
        <path
          id="Path_19"
          data-name="Path 19"
          d="M18.125,14.009H15.246V11.13a.618.618,0,0,0-1.237,0v2.879H11.13a.592.592,0,0,0-.618.618.6.6,0,0,0,.618.618h2.879v2.879a.6.6,0,0,0,.618.618.615.615,0,0,0,.618-.618V15.246h2.879a.618.618,0,1,0,0-1.237Z"
          transform="translate(-3.214 -3.214)"
          fill="#cbcbcb"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          d="M11.414,4.457a6.954,6.954,0,1,1-4.92,2.037,6.91,6.91,0,0,1,4.92-2.037m0-1.082a8.039,8.039,0,1,0,8.039,8.039,8.037,8.037,0,0,0-8.039-8.039Z"
          fill="#cbcbcb"
        />
      </g>
    </svg>
  </div>
);

export default RoundAddIcon;
